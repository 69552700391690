

















































import { Vue, Component, PropSync } from 'vue-property-decorator';
import ErrorAllianzProcessViewModel
  from '@/vue-app/view-models/components/contract-savings/allianz/error-allianz-process-view-model';

@Component({ name: 'ErrorAllianzProcess' })
export default class ErrorAllianzProcess extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  private is_open!: boolean;

  error_allianz_process_view_model = Vue.observable(new ErrorAllianzProcessViewModel());
}
